import {
  ref,
  computed,
  watch
} from '@vue/composition-api'
import {
  useToast
} from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment-timezone'
import store from '@/store'
import { useUtils as useI18nUtils } from "@core/libs/i18n"
import { debounce } from 'lodash'

export default function useList() {
  const refListTable = ref(null)

  const toast = useToast()
  const { t } = useI18nUtils()

  const trans = {
    'actions.download': t('actions.download'),
    'messages.info.begin_download': t('messages.info.begin_download'),
  }

  store.dispatch('incident/getAll')

  const incidentOptions = computed(() => store.getters['incident/getSelectedItems'])
  const incidentFilter = ref(null)
  const createdAtFilter = ref(null)

  store.dispatch('incident_list/getAll')
  const listFilter = ref(null)
  const listOptions = computed(() => store.getters['incident_list/getSelectedItems'])

  const tableColumns = [{
      label: 'incident.title',
      key: 'incident.name',
      sortable: true
    },
    {
      label: 'incident_list.title',
      key: 'incident.incident_list.name',
      sortable: true
    },
    {
      label: 'log_incident.comment',
      key: 'comment',
      sortable: true
    },
    {
      label: 'router.title',
      key: 'router.description',
      sortable: true
    },
    {
      label: 'users_app.title',
      key: 'router.user_app',
      sortable: true
    },
    {
      label: 'date.created_at',
      key: 'created_at',
      sortable: true,
      formatter: 'formatDateAssigned',
    },
    {
      label: 'date.updated_at',
      key: 'updated_at',
      sortable: true,
      formatter: 'formatDateAssigned',
    },
  ]

  const perPage = ref(10)
  const totalList = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const action = ref('list')
  const buttonExport = ref(false)
  const loadingToast = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems
      .length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalList.value,
    }
  })

  const fetchList = (ctx, callback) => {
    if (action.value == 'export') {
      buttonExport.value = true
      loadingToast.value.show(trans['actions.download'], trans['messages.info.begin_download'], 'secondary')
    }

    store.dispatch('log_incident/getAll', {
        q: searchQuery.value,
        max: perPage.value,
        'page[n]': currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        id_incident_list: listFilter.value,
        id_incident: incidentFilter.value,
        created_at: createdAtFilter.value,
        action: action.value
      })
      .then(response => {
        if (action.value == 'export') {
          action.value = 'list'
        } else {
          callback(response.data)
          totalList.value = response.meta.total
        }
      })
      .catch((e) => {
        console.log(e)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error obteniendo la información',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
      .finally(() => {
        buttonExport.value = false
        loadingToast.value.close()
      })
  }

  const formatDateAssigned = value => {
    const formattedDate = moment(value).format('DD/MM/YYYY HH:mm:ss')
    return formattedDate
  }

  const refetchData = () => {
    refListTable.value.refresh()
  }

  watch([listFilter, incidentFilter, createdAtFilter, currentPage, perPage, ],
    () => {
      refetchData()
    })

  watch([ searchQuery ], debounce(() => {
    refetchData()
  }, 500))

  return {
    tableColumns,
    perPage,
    currentPage,
    totalList,
    fetchList,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,
    formatDateAssigned,

    listFilter,
    listOptions,
    incidentOptions,
    incidentFilter,
    createdAtFilter,
    refetchData,
    action,
    buttonExport,
    loadingToast,
  }
}
