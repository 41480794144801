<template>
  <b-card no-body class="m-2 full">
    <b-card-header class="pb-50">
      <h5>
        Filtros
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="4"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label class="text-capitalize">{{ $t('incident_list.title') }}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="listFilter"
            :options="listOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:listFilter', val)"
            multiple
          />

        </b-col>

        <b-col
          cols="4"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label class="text-capitalize">{{ $tc('incident.title') }}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="incidentFilter"
            :options="incidentOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:incidentFilter', val)"
            multiple
          />
        </b-col>
        <b-col
          cols="4"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label class="text-capitalize">{{ $tc('date.created_at') }}</label>
          <flat-pickr
            :value="createdAtFilter"
            class="form-control"
            @input="(val) => $emit('update:createdAtFilter', val)"
            :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    flatPickr,
    vSelect,
  },
  props: {
    listOptions: {
      type: Array,
      default: null,
    },
    listFilter: {
      type: Array,
      default: null,
    },
    incidentOptions: {
      type: Array,
      default: null,
    },
    incidentFilter: {
      type: Array,
      default: null,
    },
    createdAtFilter: {
      type: String,
      default: null,
    },
  },
}
</script>